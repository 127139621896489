import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import HighlightIcon from "@material-ui/icons/Highlight"
import SaveIcon from "@material-ui/icons/Save"

import { toBlob } from "html-to-image"
import { saveAs } from "file-saver"

import QRCode from "qrcode.react"

class QRCodeWrapper extends React.Component<{
  value: string
  placeHolderText?: string
}> {
  render() {
    return (
      <Box component="div">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} xl={3} justify="center" container>
            <QRCode
              id="qrcode"
              size={256}
              value={this.props.value}
              fgColor={
                this.props.value && this.props.value.length > 0
                  ? "black"
                  : " grey"
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} xl={9}>
            <Hidden smDown>
              <Box mt={2} width={1} display="flex">
                <Box display="inline" color="warning.main">
                  <IconButton size="small" disabled>
                    <HighlightIcon color="action" />
                  </IconButton>
                </Box>
                <Box ml={1} display="inline">
                  <Typography variant="h6" gutterBottom>
                    Tips
                  </Typography>
                </Box>
              </Box>

              <Box mt={2} mb={2} display="block">
                <Typography variant="body1" gutterBottom>
                  QR codes give you quick access to websites without having to
                  type or remember a web address. You can use the Camera app on
                  your iPhone, iPad, or iPod touch to scan a QR code. <span />
                  <a
                    href="https://support.apple.com/en-us/HT208843"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More...
                  </a>
                </Typography>
              </Box>
            </Hidden>

            <Box
              component="span"
              m={4}
              width={1}
              mx="auto"
              display="flex"
              justifyContent="center"
            >
              <Button
                disabled={!this.props.value || this.props.value.length === 0}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  toBlob(document.getElementById("qrcode")).then(blob => {
                    saveAs(blob, "qrcode.png")
                  })
                }}
              >
                Save image
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default QRCodeWrapper
